
export const toTimeFormat = (datetime) => {
    const options = { hour: 'numeric', minute: 'numeric' };
    try {
        return new Intl.DateTimeFormat('en-NZ', options).format(datetime)
    } catch {
        return "";
    }

}

export const getTimeRange = (fulfillmentTime, hourRange) => {
    if (!fulfillmentTime) return "";

    const separatorIndex = fulfillmentTime.indexOf(":");

    const hour = parseInt(fulfillmentTime.substring(0, separatorIndex));
    const hourWithRange = hour + hourRange;
    const getHourLabel = (hour) => hour > 12 ? `${hour % 12}` : `${hour}`;

    const minute = fulfillmentTime.substring(separatorIndex + 1);
    const minuteLabel = minute == 0 ? "00" : minute;

    let defTime = `${getHourLabel(hour)}:${minuteLabel}${hour >= 12 ? "pm" : "am"}`;
    let rangedTime = `${getHourLabel(hourWithRange)}:${minuteLabel}${hourWithRange >= 12 ? "pm" : "am"}`;;

    return hourRange >= 0 ? `${defTime} - ${rangedTime}` : `${rangedTime} - ${defTime}`;
}

export const toDateFormat = (datetime) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    try {
        return new Intl.DateTimeFormat('en-NZ', options).format(datetime)
    } catch {
        return "";
    }
}